import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
import HeatMaps from './bpmn/heatmaps';
import AddUser from './usermanagement/tenants/addUser';

const Login = lazy(() => import('./session/Login'));
const FrgtPswd = lazy(() => import('./session/forgotPassword'));
const Signup = lazy(() => import('./session/signup'));
const DMN = lazy(() => import('./dmn/dmn'));
const UserProfile = lazy(() => import('./usermanagement/userprofile'));
const ChangePaswd = lazy(() => import('./usermanagement/changePassword'));

class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
         
          <Route exact path="/home/dmn" component={ DMN } />
          <Route exact path="/home/userprofile" component={ UserProfile } />
          <Route exact path="/home/changePassword" component={ ChangePaswd } />
          <Route exact path="/home/createuser" component={ AddUser } />
          <Route path="/login" component={ Login } />
          <Route path="/register" component={ Signup } />
          <Route path="/forgotpassword" component={ FrgtPswd } />
          <Route path="/" exact={true} >
            <Redirect to="/login" />
          </Route>
          <Route path="/home" exact>
            <Redirect to="/home/dmn" />
          </Route>
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;