import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import Logo from "../images/logo1.png";
import LogoSM from "../images/logo-sm.png";

import { getUserdetail} from '../service/utility';

class Sidebar extends Component {

  state = {

  };


  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
     // { path: '/home/scripts', state: 'appsMenuOpen' },
      { path: '/home/dmn', state: 'basicUiMenuOpen' },
      //{ path: '/home/bpmn', state: 'formElementsMenuOpen' },
      //{ path: '/home/dashboard', state: 'tablesMenuOpen' },
     // { path: '/home/users', state: 'iconsMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));
  }

  render() {
    var json = getUserdetail();
    var showUser = false;
    var showSubUser = false;
    if (json) {
      if (json.accountType === "Admin") {
        showUser = true;
      }
      else if (json.accountType === "Tenant" || json.accountType === "Tenant-User") {
        showSubUser = true;
      }
    }
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div id='sidebar-brand' className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <a className="sidebar-brand brand-logo" href="index.html">
            {/* <i class="fa fa-cube"></i> <span class="sidebar-nav-mini-hide">VIZUAL PLATFORM</span> */}
            {/* <img src={require('../../assets/images/logo.svg')} alt="logo" /> */}
            <img class="responsive" src={Logo} alt="" />
          </a>
          <a className="sidebar-brand brand-logo-mini" href="index.html">
            {/* <i class="fa fa-cube"></i> <p class="sidebar-nav-mini-hide">VIZUAL</p> */}
            {/* <img src={require('../../assets/images/logo-mini.svg')} alt="logo" /> */}
            <img class="responsive" src={LogoSM} alt="" />
          </a>
        </div>
        <ul className="nav">
         
          <li className={this.isPathActive('/dmn') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
            <Link className="nav-link" to="/home/dmn">
              <span className="menu-icon"><i className="mdi mdi-cellphone-settings-variant"></i></span>
              <span className="menu-title"><Trans>DMN Rules</Trans></span>
            </Link>
          </li>
        </ul>
        <p class="help">
          <span className="menu-icon"><i className="mdi mdi-help-circle-outline"></i></span>&nbsp; Help</p>
      </nav>

    );
  }

  isPathActive(path) {
    return (window.location.href.search(path) !== -1);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);