import React from "react";
import { postdata, deleteData } from '../../service/httputlity';
import VizualTable from '../../shared/table';
import { toast } from 'react-toastify';

import { options } from "../../service/utility";
import { confirm } from "react-confirm-box";

export default class AddUser extends React.Component {
  timer = null;
  state = {
    usersList: { data: [], totalRows: 0 },
    userid: '',
    name: '',
    desc: '',
    columns: [{ field: 'fName', headerName: "First Name", flex: 0.5 }, { field: 'lName', headerName: "Last Name", flex: 0.5 }, { headerName: "Email", field: 'email', flex: 1 },
    { headerName: "Status", field: 'status', flex: 0.5 },
    {
      headerName: "Action", field: 'action', flex: 0.6,
      renderCell: (params) => (
        <div className="">
          <button className="btn text-dark" onClick={() => this.editClicked(params.row)}  >
            <i class="mdi mdi-pencil text-primary"></i>
          </button>
          <button className="btn btn-sm text-dange" onClick={() => this.deleteClicked(params.row)}  >
            <i class="mdi mdi-delete"></i>
          </button>
        </div>
      )
    }],
    skip: 0,
    limit: 20,
    search: '',
    sort: 'time',
    sortOrder: 'desc',
    listView: true
  };

  componentDidMount() {
    this.getusersList();
  }

  handleChange = this.handleChange.bind(this);

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSearch(event) {
    if (this.timer) {
      this.timer = null;
    }
    this.setState({
      [event.target.name]: event.target.value
    });
    this.timer = setTimeout(() => {
      this.getusersList();
    }, 1000);
  }

  editClicked(row) {
    this.setState({
      listView: false,
      firstName: row.fName,
      lastName: row.lName,
      editView: true,
      password: "",
      confirmPassword: "",
      editId:row.id
    });
  }

  deleteClicked(row) {
    confirm({ title: "Delete User", body: "Selected User will be deleted. Do you wish to proceed ?" }, options).then((result) => {
      if (result) {
        deleteData('deleteuser?id=' + row._id).then((d) => {
          if (d) {
            this.setState({ userid: '' });
            this.getusersList();
          }
        }, (e) => {
        });
      }
    });
  }

  getusersList() {
    var json = {
      skip: this.state.skip,
      limit: this.state.limit,
      search: this.state.searchVal,
      sort: this.state.sort,
      sortOrder: this.state.sortOrder,
    };
    postdata('getuserslist', json).then((d) => {
      if (d) {
        this.setState({
          usersList: d
        });
      }
    }, (e) => {
    });
  }

  refreshTable(params) {
    this.setState({
      skip: params.skip,
      limit: params.limit,
      sort: params.sort,
      sortOrder: params.sortOrder
    });
    this.getusersList();
  }

  addClicked() {
    this.setState({
      listView: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      editView: false,
      emailExist:false
    });
  }

  cancelClicked() {
    this.setState({
      listView: true
    });
  }

  updateSubUserProfile(){
    var json = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      id:this.state.editId
    };
    postdata("updateUser", json).then((d) => {
      if (d) {
        toast.success("Updated User ", {
          position: toast.POSITION.TOP_CENTER
        });
        this.setState({
          listView: true
        });
      }
    }, (e) => {
      toast.error("Failed updating user.", {
        position: toast.POSITION.TOP_CENTER
      });
    });
  }

  createNewSubUser(){
    var json = { username: this.state.email };
        postdata("verifyemail", json).then((d) => {
          if (d && !d.exist) {
            json = this.state;
            postdata("createUser", json).then((d) => {
              if (d) {
                toast.success("Created user successfully. Email verification mail will be sent to " + this.state.email, {
                  position: toast.POSITION.TOP_CENTER
                });
                this.setState({
                  listView: true
                });
                this.getusersList();
              }
            }, (e) => {
              toast.error("Failed adding new user.", {
                position: toast.POSITION.TOP_CENTER
              });
            });
          }
          else {
            this.setState({
              emailExist: true
            });
          }
        },
          (e) => {
            console.log(e);
          });
  }

  createUser() {
    if (this.state.password === this.state.confirmPassword) {
      if (this.state.editView) {
        this.updateSubUserProfile()
      }
      else {
        this.createNewSubUser();
      }
    }
    else{
      toast.error("Paswords do not match.", {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  render() {
    return (
      <div class="card">
        <div class="card-title mb-0  color-black">
          <h4 className='title'>User List</h4>
        </div>
        <div class="card-body" >
          <div id="actionbtns" className={` col-12 padding-none align-items-baseline justify-content-between ${this.state.listView ? "d-flex" : "hide"}`}>
            <div class="d-flex">
              <button className="btn btn-primary  m-2" onClick={() => this.addClicked()}  >
                Add User
              </button>
            </div>
            <div class="form-group seach-input">
                <input placeholder="search" type="text" class="form-control" name="searchVal" onChange={(e) => { this.handleSearch(e) }} value={this.state.searchVal}  ></input>
            </div>
          </div>
          <div id="tableView" className={`content bpmntab table-responsive ${this.state.listView ? "show" : "hide"}`}>
            <VizualTable setSelection={(id) => { this.setState({ editId: id }) }} refresh={(params) => { this.refreshTable(params) }} columns={this.state.columns} data={this.state.usersList} />
          </div>
          <div className={` ${this.state.listView ? "hide" : "show"}`}>
            <div className="form-group">
              <input type="text" className="form-control form-control-lg" name="firstName" onChange={this.handleChange} value={this.state.firstName} placeholder="First Name" />
            </div>

            <div className="form-group">
              <input type="text" className="form-control form-control-lg" name="lastName" onChange={this.handleChange} value={this.state.lastName} placeholder="Last Name" />
            </div>

            <div className={`form-group ${this.state.editView ? "hide" : "show"}`}>
              <input className="form-control form-control-lg" placeholder="Only Co-oporate EMail ID" id="email" type="email" name="email" onChange={this.handleChange} value={this.state.email} />
              <div className={`invalid-feedback ${this.state.emailExist ? 'show' : 'hide'}`}>
                Email Id already registered
              </div>
            </div>

            <div className={`form-group ${this.state.editView ? "hide" : "show"}`}>
              <input type="password" className="form-control form-control-lg" name="password" onChange={this.handleChange} value={this.state.password} placeholder="Password" />
            </div>
            <div className={`form-group ${this.state.editView ? "hide" : "show"}`}>
              <input type="password" className="form-control form-control-lg" name="confirmPassword" onChange={this.handleChange} value={this.state.confirmPassword} placeholder="Confirm Password" />
              <div className={`invalid-feedback ${(this.state.password !== this.state.confirmPassword && this.state.confirmPassword !== '') ? 'show' : 'hide'}`}>
                Passwords doesn't match
              </div>
            </div>

            <div className="mt-3 d-flex">
              <button className="btn btn-block btn-primary m-2 w-10" onClick={() => this.createUser()}>Create</button>
              <button className="btn btn-block btn-danger  m-2 w-10" onClick={() => this.cancelClicked()}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

}
