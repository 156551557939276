import React from "react";
import { DataGrid } from '@material-ui/data-grid';

export default class VizualTable extends React.Component{
    
    state = {
      limit:20,
      skip:0,
      sort:'time',
      sortOrder:'desc',
      selection:''
    };

    render(){
    
      return(
             <DataGrid sortingOrder={['asc', 'desc']} rowCount={this.props.data.totalRows} rows={this.props.data.data} columns={this.props.columns}  pageSize={this.state.limit} rowsPerPageOptions={[20, 50, 100]} 
              onPageChange={(e)=>{this.setState({skip:e}); this.props.refresh(this.state);}} 
              onPageSizeChange={(e)=>{this.setState({limit:e}); this.props.refresh(this.state);}} 
              onSortModelChange={(e)=>{console.log(e);this.setState({sort:e[0].field,sortOrder:e[0].sort}); this.props.refresh(this.state);}} 
              onSelectionModelChange={(e)=>{this.setState({selection:e[0]}); this.props.setSelection(e[0])}} />
          
      );
    }
}