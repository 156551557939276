import React from "react";
import Moment from 'moment';

export function saveUserDetail(userdata){
    sessionStorage.setItem("userdata", JSON.stringify(userdata));
}

export function getUserdetail(){
    var d= sessionStorage.getItem("userdata");
    return JSON.parse(d);
}

 
export function deleteUserDetail(){
    sessionStorage.clear();
}


export function downloadURI(data,name ) {
    var bb = new Blob([data ], { type: 'text/xml' });
    var a = document.createElement('a');
    a.download = name;
    a.href = window.URL.createObjectURL(bb);
    a.click();
  }

  export function formatDate(d){
    Moment.locale('en');
    return Moment(d).format('DD MMM yyyy');
  }

  export function formatDateTime(d){
    Moment.locale('en');
    return Moment(d).format('DD MMM yyyy hh:mm:ss a');
  }

  export var options = {
    render: (message, onConfirm, onCancel) => {
      return (
          <div class="modal_cust">
            <div class="dialog_cust">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">{message.title}</h5>
                  <button onClick={onCancel} type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>{message.body}</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary btn-popup" onClick={onCancel} data-dismiss="modal">Cancel</button>
                  <button type="button"  onClick={onConfirm}  className={`btn btn-danger btn-popup ${message.isConfirm ? "hide": "" }`}>Delete</button>
                  <button type="button"  onClick={onConfirm}  className={`btn btn-primary btn-popup ${message.isConfirm ? "": "hide" }`} >Confirm</button>
                </div>
              </div>
            </div>
        </div>
      );
    }
  };