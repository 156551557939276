import React from "react";
import "bpmn-js/dist/assets/diagram-js.css";
import { getData, postdata, putData } from '../service/httputlity';
import VizualTable from '../shared/table';
import BpmnModeler from 'bpmn-js/lib/Modeler';
import { toast } from 'react-toastify';
import './bpmnmonitoring.scss';
import { Tabs, Tab } from 'react-tab-view'

export default class HeatMaps extends React.Component {
    timer = null;

    state = {
        hideSidePanel: false,
        diagram: '',
        container: '',
        listView: true,
        xml: '',
        editId: '',
        modeler: {},
        name: '',
        desc: '',
        categoryList: [],
        data: { data: [], totalRows: 0 },
        columns: [{ field: 'bpmnID', headerName: "ID", flex: 1 },
        { field: 'name', headerName: "Name", flex: 1 },
        {
            headerName: "Action", field: 'action', flex: 0.25,
            renderCell: (params) => (
                <div className="icon-center">
                    <button className="btn" onClick={() => this.monitorClicked(params.row)}  >
                        <i class="mdi mdi-chevron-double-right text-primary"></i>
                    </button>
                </div>
            )
        }],
        Logcolumns: [
            { field: 'instanceId', headerName: "Instance Id", flex: 1 },
            { field: 'timeTaken', headerName: "Time Taken (ms)", flex: 1 },
            { field: 'timestamp', headerName: "Time Stamp", flex: 1 },
            { field: 'status', headerName: "Status", flex: 1 },
        ],
        Log1columns: [
            {
                field: 'instanceId', headerName: "Instance Id", flex: 1,
                renderCell: (params) => (
                    <div className="icon-center">
                        <a className="href-style" onClick={(event) => this.instanceIdClicked(params.row, event)}  >
                            {params.row.instanceId}
                        </a>
                    </div>
                )
            },
            { field: 'timeTaken', headerName: "Time Taken", flex: 1 },
            { field: 'timestamp', headerName: "Time Stamp", flex: 1 },
            { field: 'status', headerName: "Status", flex: 1 },
        ],
        logData: { data: [], totalRows: 0 },
        logFilteredData: { data: [], totalRows: 0 },
        analysisView: false,
        skip: 0,
        limit: 20,
        search: '',
        sort: 'time',
        sortOrder: 'desc',
        inputFile: '',
        logs: [],
        logviewdata: {}
    };


    instanceIdClicked(row, event) {
        console.log(row);
        this.setState({ logviewdata: row });
        var element = document.querySelectorAll('[data-tab-id="1"]');
        if (element && element.length > 0) {
            element[0].click();
        }
        event.stopPropagation();
    }


    analyzeClicked(taskId) {
        var data = this.state.logData.data;
        var selectedRows = [];
        for (var i = 0; i < data.length; i++) {
            if (data[i].taskId == taskId) {
                selectedRows.push(data[i]);
            }
        }
        this.setState({ logFilteredData: { data: selectedRows, totalRows: selectedRows.length }, analysisView: true });
    }

    componentDidMount() {
        this.getBPMNList();
    }

    handleChange = this.handleChange.bind(this);

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSearch(event) {
        if (this.timer) {
            this.timer = null;
        }
        this.setState({
            [event.target.name]: event.target.value
        });
        this.timer = setTimeout(() => {
            this.getBPMNList();
        }, 1000);
    }

    monitorCancelled() {
        this.setState({
            editId: '',
            listView: true,
            xmlView: false
        });
        this.getBPMNList();
    }

    highLightLogData(ele, id) {

        this.analyzeClicked(id);
        var element = document.getElementsByClassName("selected-custom");
        if (element && element.length > 0) {
            element[0].classList.remove("selected-custom");
        }
        ele.classList.add("selected-custom");
    }

    initBPMNUI(selectedRow) {
        var element = document.querySelectorAll('[data-element-id="' + selectedRow.taskId + '"]');
        if (element && element.length > 0) {
            var newcontent = document.createElement('g');
            newcontent.id = 'div-' + selectedRow.taskId;
            newcontent.classList = ["djs-visual"]
            newcontent.innerHTML = selectedRow.status;
            if (!document.getElementById(newcontent.id)) {
                element[0].addEventListener("click", (event) => { this.highLightLogData(element[0], selectedRow.taskId) });
                element[0].appendChild(newcontent);
                element.onclick = this.highLightLogData;
                if (selectedRow.status == "SUCCESS") {
                    element[0].classList.add("statusSucess");
                }
                else if (selectedRow.status == "ERROR") {
                    element[0].classList.add("statusFailed");
                    element[0].classList.add("test");
                }
                else {
                    element[0].classList.add("statusGrey");
                }
                element[0].scrollIntoView(true);
            }
        }
    }

    formatLogs(logs) {
        var data = [];
        for (var i = 0; i < logs.length; i++) {
            var mesg = JSON.parse(logs[i].message);
            mesg.id = logs[i]._id;
            data.push(mesg);
            this.initBPMNUI(mesg);
        }
        this.setState({ logData: { data: data, totalRows: logs.length } });
        var ele = document.getElementsByClassName("djs-hit-all");
        if (ele && ele.length > 0) {
            for (var i = 0; i < ele.length; i++) {
                ele[i].addEventListener("mousedown", (event) => { event.stopPropagation(); });
                ele[i].addEventListener("dblclick", (event) => { event.stopPropagation(); });
            }
        }
    }

    monitorClicked(row) {
        this.setState({ listView: false, logData: { data: [], totalRows: 0 } });
        getData('getbpmndetail?id=' + row._id + '&monitor=true').then((d) => {
            if (d) {
                console.log(d);
                for(var i=0;i<d.logs.length;i++){
                    var json = JSON.parse(d.logs[i].message);
                    d.logs[i].instanceId = json.instanceId;
                }
                this.setState({
                    diagram: atob(d.bpmn.xml.xml),
                    bpmnName:d.bpmn.name,
                    logs: d.logs
                });
                this.renderbpmn(this.state.diagram);
            }
        }, (e) => {
        });
    }

    getBPMNList() {
        this.setState({ editId: '' });
        var json = {
            skip: this.state.skip,
            limit: this.state.limit,
            search: this.state.searchVal,
            sort: this.state.sort,
            sortOrder: this.state.sortOrder,
        };
        postdata('getbpmnlist', json).then((d) => {
            if (d && d.data) {
                this.setState({
                    data: d
                });
            }
        }, (e) => {
        });
    }

    renderbpmn(xml) {
        var container = document.getElementById("container_monitor")
        if (container) {
            container.innerHTML = "";
        }
        if (container) {
            try {
                var bpmnModeler = new BpmnModeler({
                    container: container,
                });
                bpmnModeler.importXML(xml).then((e) => {

                    var element = document.getElementsByClassName('djs-hit-stroke');
                    element[0].style["pointer-events"] = "none";
                    this.formatLogs(this.state.logs);
                }, (e) => {
                    toast.error("Import Failed. " + e, {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
                this.setState({ modeler: bpmnModeler });
            }
            catch (e) {
                toast.error("Import Failed. " + e, {
                    position: toast.POSITION.TOP_CENTER
                });
                this.editCancelled();
            }
        }
    }

    refreshTable(params) {

        this.setState({
            skip: params.skip,
            limit: params.limit,
            sort: params.sort,
            sortOrder: params.sortOrder
        });
        this.getBPMNList();
    }
    render() {
        return (
            <div class="card h-100">
                <div class="card-title mb-0">
                    <h4 className='title'>Business Processes Monitoring</h4>
                    <div id="actionbtns" className={`float-right ${this.state.listView ? "hide" : "show"}`}>
                        <div class="float-right icon-set-box">
                            <button className={`btn btn-info m-2`} onClick={() => this.monitorCancelled()}  >
                                <i class="bi bi-x-lg" aria-hidden="true" title="Cancel BPMN"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body pt-2">
                    <div id="actionbtns" className={`py-0 align-items-baseline justify-content-between ${this.state.listView ? "d-flex" : "hide"}`}>
                        <div class="form-group seach-input">
                            <input placeholder="search" type="text" class="form-control" name="searchVal" onChange={(e) => { this.handleSearch(e) }} value={this.state.searchVal}  ></input>
                        </div>
                    </div>
                    <div id="tableView" className={`content bpmntab table-responsive ${this.state.listView ? "show" : "hide"}`}>
                        <VizualTable className="table table-striped" setSelection={(id) => { this.setState({ editId: id }) }} refresh={(params) => { this.refreshTable(params) }} columns={this.state.columns} data={this.state.data} />
                    </div>

                    <div className={`modal ${this.state.listView ? "hide" : "show"}`} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog fullscreen">
                            <div class="modal-content h-100">
                                <div class="card-title px-3 py-1 mb-0">
                                    <h4 className='title'>Monitoring -- {this.state.bpmnName}</h4>
                                    <button className={`btn btn-danger m-2`} onClick={() => this.monitorCancelled()}  >
                                        <i class="bi bi-x-lg" aria-hidden="true" title="Cancel BPMN" data-bs-dismiss="modal" aria-label="Close"></i>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div id="container_monitor" className="pointer-event-none w-100" ></div>
                                    <div className={`table_monitor ${(this.state.logData.data.length == 0) ? "hide" : "show"}`}>
                                        <div className={`h-100 ${this.state.analysisView ? "hide" : "show"}`} >
                                            <VizualTable setSelection={(id) => { }} className={`table table-striped`} columns={this.state.Logcolumns} data={this.state.logData} />
                                        </div>
                                        <div className={`h-100 ${this.state.analysisView ? "show" : "hide"}`} >
                                            <button className="btn btn-outline-danger btn-sm m-2 back_btn_custom" onClick={()=>{this.setState({analysisView : false})}}><i class="bi bi-backspace"></i> Back</button>
                                            <Tabs headers={["Logs", "Data"]}>
                                                <Tab>
                                                    <VizualTable setSelection={(id) => { }} className={`table table-striped `} columns={this.state.Log1columns} data={this.state.logFilteredData} />
                                                </Tab>
                                                <Tab>
                                                <p>id: {this.state.logviewdata.id}</p>
                                                <p>index:  {this.state.logviewdata.index}</p>
                                                <p>instanceId:  {this.state.logviewdata.instanceId}</p>
                                                <p> processId:  {this.state.logviewdata.processId}</p>
                                                <p>status:  {this.state.logviewdata.status}</p>
                                                <p> taskId: {this.state.logviewdata.taskId}</p>
                                                <p> timeTaken:  {this.state.logviewdata.timeTaken}</p>
                                                <p> timestamp:  {this.state.logviewdata.timestamp}</p>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                    <div className={`card-body ${this.state.logData.data.length == 0 ? "show" : "hide"}`} >
                                       <p>No Logs Available</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
